import React, { useEffect, useState } from "react";
import IndexNav from "../../Components/IndexNav";
import { Navigate, useNavigate } from "react-router-dom";
import {
  Container,
  Content,
  LeftSide,
  RightSide,
  LeftWrapper,
  Title,
  Text,
  Button,
  SvgItem,
  SectionTitle,
  SectionContent,
} from "./Styled";
import homePageData from "./homepage-content.json";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import homeHero from "../../Images/home-hero.webp";
import BoardFooter from "../../Components/BoardFooter";

const Index = () => {
  const [pageContent, setPageContent] = useState({});

  useEffect(() => {
    setPageContent(homePageData);
    document.title =
      "OlO Tasks: Free task management for anyone, anywhere and anytime";
  }, []);

  if (localStorage.getItem("token")) return <Navigate push to="/boards" />;

  return (
    <>
      <IndexNav />
      <Container>
        <Content>
          <LeftSide>
            <LeftWrapper>
              <Title>{pageContent?.title}</Title>
              <Text>{pageContent?.intro}</Text>
              {pageContent?.sections?.map((section, index) => (
                <Box key={index}>
                  <SectionTitle variant="h4">{section.title}</SectionTitle>
                  {section.points ? (
                    <List>
                      {section.points.map((point, idx) => (
                        <ListItem
                          key={idx}
                          style={{ paddingTop: 0, paddingBottom: 0 }}
                        >
                          <ListItemText primary={point} />
                        </ListItem>
                      ))}
                    </List>
                  ) : (
                    <SectionContent variant="body1">
                      {section.content}
                    </SectionContent>
                  )}
                </Box>
              ))}

              <Typography variant="h6" sx={{ marginTop: "20px" }}>
                {pageContent?.conclusion}
              </Typography>
              <Button to={"/register"}>Sign up - it's free</Button>
            </LeftWrapper>
          </LeftSide>
          <RightSide>
            <SvgItem src={homeHero} />
          </RightSide>
        </Content>
        <BoardFooter isColor />
      </Container>
    </>
  );
};

export default Index;
