import React from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { ButtonGoogle, GoogleIcon } from "./Styled";
import googlesvg from "./icons/google-logo.5867462c.svg";
import { getGoogleToken, loginWithGoogle } from "../../Services/userService";

const GoogleLoginButton = () => {
  const dispatch = useDispatch();
  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      loginWithGoogle(tokenResponse.access_token, dispatch);
    },
    onError: (error) => console.error("Login Failed:", error),
  });

  return (
    <ButtonGoogle
      style={{
        backgroundColor: "white",
        color: "black",
        marginTop: "0.5rem",
      }}
      onClick={() => login()}
      type="button"
    >
      <GoogleIcon src={googlesvg} />
      Google
    </ButtonGoogle>
  );
};

export default GoogleLoginButton;
