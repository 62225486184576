import React, { useEffect, useRef, useState } from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import {
  AddTitleCardContainer,
  CardContainer,
  CardWrapper,
  Container,
  FooterButton,
  Header,
  Span,
  TitleInput,
  TitlePlaceholder,
  TitleNewCardInput,
  ExpandButtonWrapper,
} from "./styled";
import { ClickableIcon } from "../../Pages/BoardPage/CommonStyled";
import BottomButtonGroup from "../BottomButtonGroup/BottomButtonGroup";
import Card from "../Card/Card";
import { useDispatch, useSelector } from "react-redux";
// import Menu from "@mui/material/Menu";
import Menu from "../Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import UnfoldLessDoubleIcon from "@mui/icons-material/UnfoldLessDouble";
import UnfoldMoreDoubleIcon from "@mui/icons-material/UnfoldMoreDouble";
import localStorageService from "../../Services/localStorageService";
import {
  createCard,
  DeleteList,
  listTitleUpdate,
} from "../../Services/boardService";
import { Droppable, Draggable } from "react-beautiful-dnd";
import IconButton from "../ReUsableComponents/IconButton";
import { SortByAlpha, SortSharp } from "@mui/icons-material";
import { get, orderBy, sortBy } from "lodash-es";

const List = (props) => {
  const { info } = props;
  const dispatch = useDispatch();
  const [clickTitle, setClickTitle] = useState(false);
  const [clickFooter, setClickFooter] = useState(false);
  const [newCardTitle, setNewCardTitle] = useState("");
  const [currentListTitle, setCurrentListTitle] = useState(info.title);
  const [isCollapsed, setIsCollapsed] = useState(info.isCollapsed);
  const [sort, setSort] = useState(null);
  const { filters, members } = useSelector((state) => state.board);
  const cards = useSelector((state) => state.board.cards);
  const user = useSelector((state) => state.user.userInfo);
  const ref = useRef();

  useEffect(() => {
    const isCollapsedLocal = localStorageService.getItem(
      "isCollapsed" + info._id
    );
    if (isCollapsedLocal !== null) {
      setIsCollapsed(JSON.parse(isCollapsedLocal));
    }
  }, []);

  const loggedInUser = members.find((member) => member.user === user._id);

  useEffect(() => {
    setCurrentListTitle(info.title);
  }, [info.title]);

  const handleFooterClick = async () => {
    setNewCardTitle("");
    await createCard(newCardTitle, info._id, props.boardId, dispatch);
    ref && ref.current && ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const handleFooterCloseClick = () => {
    setClickFooter(false);
    setNewCardTitle("");
  };

  const handleOnChangeTitle = (e) => {
    setCurrentListTitle(e.target.value);
  };
  const handleChangeTitle = async () => {
    if (info.title !== currentListTitle)
      await listTitleUpdate(
        info._id,
        props.boardId,
        currentListTitle,
        dispatch
      );
  };

  const handleDeleteClick = () => {
    DeleteList(info._id, props.boardId, dispatch);
  };

  const handleListCollapse = () => {
    setIsCollapsed(!isCollapsed);
    localStorageService.setItem(
      "isCollapsed" + info._id,
      JSON.stringify(!isCollapsed)
    );
  };

  const handleClickOutside = (e) => {
    if (ref.current)
      if (!ref.current.contains(e.target)) {
        setClickFooter(false);
        setNewCardTitle("");
      }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  useEffect(() => {
    if (clickFooter) {
      ref.current.scrollIntoView();
    }
  }, [clickFooter]);

  const ShowingCards = (cardIds) => {
    let listCards = cardIds
      .map((cardId) => {
        return cards[cardId];
      })
      .filter((card) => {
        if (!card) return false;
        let isValide = true;
        if (filters.text.trim() != "") {
          isValide = card.title
            .toLowerCase()
            .includes(filters.text.trim().toLowerCase());
          if (!isValide) {
            return false;
          }
        }
        if (filters.mentions?.length > 0) {
          isValide = card.members?.some((mention) =>
            filters.mentions.includes(mention.user)
          );
          if (!isValide) {
            return false;
          }
        }

        if (filters.labels?.length > 0) {
          const labelIds = filters.labels.map((label) => label._id);
          isValide = card.labels.some((label) => labelIds.includes(label._id));
          if (!isValide) {
            return false;
          }
        }
        return true;
      });

    if (sort) {
      listCards = sortBy(listCards, (card) => {
        if (["date.dueDate", "createdAt", "updatedAt"].includes(sort)) {
          const date = get(card, sort);

          if (date) {
            return new Date() - new Date(date);
          }
          return null;
        }
        return card[sort];
      });
    }

    return listCards.map((card, index) => {
      return (
        card && (
          <Card
            boardId={props.boardId}
            listId={info._id}
            key={card._id}
            index={index}
            info={card}
          />
        )
      );
    });
  };

  const listSort = (sort) => {
    setSort(sort);
  };

  const menus = [
    {
      title: "Sort",
      icon: <SortByAlpha fontSize="small" />,
      subs: [
        {
          title: "By Title",
          onClick: () => {
            listSort("title");
          },
        },
        {
          title: "By Due Date",
          onClick: () => {
            listSort("date.dueDate");
          },
        },
        {
          title: "By Recently Created",
          onClick: () => {
            listSort("createdAt");
          },
        },
        {
          title: "By Recently Updated",
          onClick: () => {
            listSort("updatedAt");
          },
        },
        {
          title: "Clear sort",
          onClick: () => {
            setSort(null);
          },
        },
      ],
    },
  ];

  if (isCollapsed) {
    menus.push({
      title: "Expand",
      icon: <UnfoldMoreDoubleIcon fontSize="small" />,
      onClick: handleListCollapse,
    });
  } else {
    menus.push({
      title: "Collapse",
      icon: <UnfoldLessDoubleIcon fontSize="small" />,
      onClick: handleListCollapse,
    });
  }

  const isAdmin = ["admin", "owner"].includes(loggedInUser?.role);
  if (isAdmin) {
    menus.push({
      title: "Delete",
      icon: <DeleteIcon fontSize="small" />,
      onClick: handleDeleteClick,
    });
  }

  return (
    <>
      <Draggable draggableId={info._id} index={props.index}>
        {(provided, snapshot) => {
          return (
            <Container
              {...provided.draggableProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
            >
              <Header
                {...provided.dragHandleProps}
                isDragging={snapshot.isDragging}
              >
                <TitlePlaceholder
                  show={clickTitle}
                  onClick={() => isAdmin && setClickTitle(true)}
                >
                  {currentListTitle}
                  {`(${info.cards.length})`}
                </TitlePlaceholder>
                {isAdmin && (
                  <TitleInput
                    onBlur={() => {
                      setClickTitle(false);
                      handleChangeTitle();
                    }}
                    ref={(input) => input && input.focus()}
                    show={clickTitle}
                    value={currentListTitle}
                    onChange={handleOnChangeTitle}
                  />
                )}

                <Menu menus={menus} />

                {/* <ClickableIcon
                  color="#656565"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MoreHorizIcon fontSize="0.1rem" onClick={() => {}} />
                </ClickableIcon>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleListCollapse}>
                    <ListItemIcon>
                      {isCollapsed ? (
                        <UnfoldMoreDoubleIcon fontSize="small" />
                      ) : (
                        <UnfoldLessDoubleIcon fontSize="small" />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {isCollapsed ? "Expand" : "Collapsed"}
                    </ListItemText>
                  </MenuItem>
                  {isAdmin && (
                    <MenuItem onClick={handleDeleteClick}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  )}
                </Menu> */}
              </Header>
              <Droppable droppableId={info._id} direction="vertical">
                {(provided, snapshot) => {
                  return (
                    <CardContainer
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      isDraggingOver={snapshot.isDraggingOver}
                    >
                      <CardWrapper dock={clickFooter}>
                        {!isCollapsed && ShowingCards(info.cards)}
                        {isCollapsed && (
                          <IconButton
                            color={"info"}
                            title={`${info.cards.length} cards`}
                            style={{ width: "100%" }}
                            icon={<UnfoldMoreDoubleIcon />}
                            onClick={handleListCollapse}
                          ></IconButton>
                        )}
                        {provided.placeholder}
                        {clickFooter && (
                          <AddTitleCardContainer ref={ref}>
                            <TitleNewCardInput
                              value={newCardTitle}
                              autoFocus={true}
                              placeholder="Enter a title for this card..."
                              height={
                                Math.floor(newCardTitle.length / 16) + "rem"
                              }
                              onChange={(e) => setNewCardTitle(e.target.value)}
                            />
                            <BottomButtonGroup
                              title="Add card"
                              clickCallback={handleFooterClick}
                              closeCallback={handleFooterCloseClick}
                            />
                          </AddTitleCardContainer>
                        )}
                      </CardWrapper>
                    </CardContainer>
                  );
                }}
              </Droppable>
              {!clickFooter && (
                <FooterButton onClick={() => setClickFooter(true)}>
                  <AddIcon fontSize="small" />
                  <Span>Add a card</Span>
                </FooterButton>
              )}
            </Container>
          );
        }}
      </Draggable>
    </>
  );
};

export default List;
