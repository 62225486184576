import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { login } from "../../Services/userService";
import Background from "../../Components/Background";
import {
  Container,
  FormCard,
  Form,
  Title,
  Input,
  Button,
  Hr,
  Link,
} from "./Styled";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../Images/logo.svg";
import GoogleLoginButton from "./GoogleLoginButton";
import { GoogleOAuthProvider } from "@react-oauth/google";
import BoardFooter from "../../Components/BoardFooter";
import IndexNav from "../../Components/IndexNav";
// import GoogleIcon from "@mui/icons-material/Google";

const Login = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get("redirect");
  const [userInformations, setUserInformations] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    document.title =
      "Login | OlO Tasks: Free task management for anyone, anywhere and anytime";
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    login(userInformations, dispatch, redirect || "/boards");
  };

  if (localStorage.getItem("token"))
    return <Navigate push to={redirect || "/boards"} />;
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_google_client_id}>
      <Container>
        <IndexNav isColor />
        <FormCard>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Title>Log in to Trello</Title>
            <Input
              type="email"
              placeholder="Enter email"
              required
              value={userInformations.email}
              onChange={(e) =>
                setUserInformations({
                  ...userInformations,
                  email: e.target.value,
                })
              }
            />
            <Input
              type="password"
              placeholder="Enter password"
              required
              value={userInformations.password}
              onChange={(e) =>
                setUserInformations({
                  ...userInformations,
                  password: e.target.value,
                })
              }
            />
            <Button>Log in</Button>
            <Title>Or continue with:</Title>
            <GoogleLoginButton />
            <Hr />
            <Link fontSize="0.85rem" to="/register">
              Sign up for an account
            </Link>
            <Link fontSize="0.85rem" to="/forgot-password">
              Forgot your password?
            </Link>
          </Form>
        </FormCard>
        <BoardFooter isColor />
      </Container>
    </GoogleOAuthProvider>
  );
};

export default Login;
