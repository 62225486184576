import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import {
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";

// Import JSON config data
import contactData from "./contact-page.json";
import BoardFooter from "../../Components/BoardFooter";
import IndexNav from "../../Components/IndexNav";
import { useDispatch, useSelector } from "react-redux";
import { openAlert } from "../../Redux/Slices/alertSlice";
import { createContact } from "../../Services/contactService";
import { Link } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

const types = [
  "feature improvement",
  "bug/issue report",
  "feature request",
  "other",
];
// Validation schema using Yup
const schema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required")
    .min(2, "Name must be at least 2 characters"),
  email: yup.string().email().required("Email is required"),
  type: yup.string().required("Type is required"),
  message: yup
    .string()
    .required("Message is required")
    .min(10, "Message must be at least 10 characters"),
});

const ContactPage = () => {
  const dispatch = useDispatch();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const user = useSelector((state) => state.user);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "feature improvement",
    },
  });

  useEffect(() => {
    if (user.isAuthenticated) {
      reset({
        name: user.userInfo.name + " " + user.userInfo.surename,
        email: user.userInfo.email,
      });
    }
  }, [user]);

  const onSubmit = (data) => {
    if (captchaVerified) {
      createContact(data)
        .then((res) => {
          setSubmitted(true);
          dispatch(
            openAlert({
              message: contactData.thankYouMessage,
              severity: "success",
            })
          );
        })
        .catch((error) => {
          console.error(error);
          dispatch(
            openAlert({
              message: error?.response?.data?.errMessage
                ? error.response.data.errMessage
                : error.message,
              severity: "error",
            })
          );
        });
    } else {
      dispatch(
        openAlert({
          message: "Please verify that you are not a robot.",
          severity: "error",
        })
      );
    }
  };

  const handleCaptcha = (value) => {
    setCaptchaVerified(!!value);
  };

  const sitekey = process.env.REACT_APP_recaptcha_site_key;

  return (
    <PageContainer>
      <ContactContent>
        <IndexNav />

        <h1>{contactData.title}</h1>
        <p>{contactData.description}</p>

        {submitted ? (
          <>
            <ThankYouMessage>{contactData.thankYouMessage}</ThankYouMessage>
            <BackHomeLink to={"/"}>
              <ArrowBack fontSize="small" /> Back to Home
            </BackHomeLink>
          </>
        ) : (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <TextField
              label={contactData.formFields.name}
              {...register("name")}
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.name}
              helperText={errors.name?.message}
            />
            <TextField
              label={contactData.formFields.email}
              {...register("email")}
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
              error={!!errors.email}
              helperText={errors.email?.message}
            />
            <FormControl
              fullWidth
              style={{
                marginTop: 16,
                marginBottom: 8,
              }}
            >
              <InputLabel id="demo-simple-select-label">
                Feedback Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                {...register("type")}
                label="Feedback type"
              >
                {types.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label={contactData.formFields.message}
              {...register("message")}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              margin="normal"
              error={!!errors.message}
              helperText={errors.message?.message}
            />
            <ReCAPTCHA
              sitekey={sitekey} // Replace with your actual reCAPTCHA site key
              onChange={handleCaptcha}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!captchaVerified}
              sx={{ mt: 2 }}
            >
              {contactData.submitButton}
            </Button>
          </Form>
        )}

        <p>
          For further inquiries, feel free to contact us via email:{" "}
          <a href={`mailto:${contactData.email}`}>{contactData.email}</a>
        </p>
        <BoardFooter isColor />
      </ContactContent>
    </PageContainer>
  );
};

// Styled components

export const PageContainer = styled.div`
  background: #fff;
  color: rgb(9, 30, 66);
  width: 100%;
  display: flex;
  justify-content: center;
  color: #091e42;
`;

const ContactContent = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const ThankYouMessage = styled.p`
  font-size: 1.2em;
  color: green;
`;

const BackHomeLink = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  font-weight: bold;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
`;

export default ContactPage;
