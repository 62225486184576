import { Avatar, Tooltip } from "@mui/material";
import { merge, upperFirst } from "lodash-es";

export default function AvatarIcon({
  id,
  name,
  surename,
  color,
  sx = {},
  avatar,
  disableTooltip = false,
}) {
  const fullName = `${upperFirst(name)} ${upperFirst(surename)}`.trim();
  const avatarShow = typeof avatar == "object" ? avatar?.small : avatar;
  const shortName = fullName
    .split(/\s+/)
    .map((s) => s.trim())
    .filter((s) => !!s)
    .map((s) => s[0].toUpperCase())
    .splice(0, 2);

  if (disableTooltip) {
    return (
      <Avatar
        sx={merge(
          {
            display: "inline-flex",
            width: "28px",
            height: "28px",
            bgcolor: color,
            fontSize: "0.875rem",
            fontWeight: "700",
          },
          sx
        )}
        src={avatarShow}
      >
        {shortName}
      </Avatar>
    );
  }

  return (
    <Tooltip title={fullName}>
      <Avatar
        sx={merge(
          {
            display: "inline-flex",
            width: "28px",
            height: "28px",
            bgcolor: color,
            fontSize: "0.875rem",
            fontWeight: "700",
          },
          sx
        )}
        src={avatarShow}
      >
        {shortName}
      </Avatar>
    </Tooltip>
  );
}
