import React, { useEffect } from "react";
import ActivityIcon from "@mui/icons-material/MessageOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  ActionContainer,
  ActionWrapper,
  CommentTitle,
  Text,
  Date,
  CommentArea,
  Container,
  HeadWrapper,
  LoadingBox,
  HeadTitle,
  Wrapper,
} from "./styled";
import moment from "moment";
import { activityUpdate } from "../../../../../Services/boardService";
import CardLoadingSvg from "../../../../../Images/cardLoading.svg";
import AvatarIcon from "../../../../AvatarIcon";
import { userFullname } from "../../../../../Utils/common";
import { Link } from "react-router-dom";
import { cardLink } from "../../../../../Utils/linkHelper";

const ActivitySection = () => {
  const board = useSelector((state) => state.board);
  const dispatch = useDispatch();
  useEffect(() => {
    if (board.activityLoading || board.activity) return;
    activityUpdate(board.shortId, dispatch).finally((data) => {});
  }, []);

  const Comment = (props) => {
    return (
      <ActionContainer>
        <AvatarIcon id={props._id} name={props.name} color={props.color} />
        <ActionWrapper>
          <CommentTitle>
            <Text>
              <b style={{ fontSize: "0.875rem" }}>{props.name}</b> on{" "}
              {props.cardTitle}
            </Text>
            <Date>{moment(props.date).fromNow()}</Date>
          </CommentTitle>
          <CommentArea>{props.action}</CommentArea>
        </ActionWrapper>
      </ActionContainer>
    );
  };

  const Action = (props) => {
    return (
      <ActionContainer>
        <AvatarIcon id={props._id} {...props.user} />
        <ActionWrapper>
          <Text>
            <b style={{ fontSize: "0.875rem" }}>{userFullname(props.user)}</b>{" "}
            {props.card ? (
              <Link
                to={cardLink(
                  props.board.shortId,
                  props.board.name,
                  props.card.cardId,
                  props.card.title
                )}
              >
                {props.message}
              </Link>
            ) : (
              props.message
            )}
          </Text>
          <Date>
            {moment(props.date).calendar().indexOf("Today") === -1
              ? moment(props.date).calendar()
              : moment(props.date).fromNow()}
          </Date>
        </ActionWrapper>
      </ActionContainer>
    );
  };

  return (
    <Container>
      <HeadWrapper>
        <ActivityIcon fontSize="small" />
        <HeadTitle>Activity</HeadTitle>
      </HeadWrapper>
      <Wrapper>
        {board.activityLoading ? (
          <LoadingBox image={CardLoadingSvg} />
        ) : (
          board.activity?.docs.map((act) => {
            return act.actionType.indexOf("card.comment") < 0 ? (
              <Action key={act._id} {...act} />
            ) : (
              <Comment key={act._id} {...act} />
            );
          })
        )}
      </Wrapper>
    </Container>
  );
};

export default ActivitySection;
