import React from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import styled from "styled-components";
import { lg } from "../BreakPoints";
import logo from "../Images/logo.svg";
import { useSelector } from "react-redux";
import Navbar from "./Navbar";
const Container = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  z-index: 100;

  ${lg({
    justifyContent: "space-between",
  })}
`;

const Icon = styled.img`
  margin-left: 5px;
  margin-top: 3px;
  height: 40px;
  ${lg({
    marginLeft: "0",
  })}
`;

const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const Link = styled(RouterLink)`
  text-decoration: underline;
  cursor: pointer;
  color: #0952cc;
`;

const Button = styled(RouterLink)`
  background-color: #0065ff;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: #0952cc;
    text-decoration: none;
    color: white;
  }
`;

const IndexNav = () => {
  const user = useSelector((state) => state.user);
  if (user.isAuthenticated) {
    return <Navbar isColor={true} />;
  }
  return (
    <Container>
      <Link to={"/"}>
        <Icon src={logo} />
      </Link>

      <RightSide>
        <Link to={"/login"}>Log in</Link>
        <Button to={"/register"}>Sign up</Button>
      </RightSide>
    </Container>
  );
};

export default IndexNav;
