import styled from "styled-components";

export const Container = styled.div`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export const Log = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: space-between;
  padding-left: 1rem;
  border-bottom: 1px solid rgba(9, 30, 66, 0.13);
`;

export const LogTitle = styled.span`
  font-size: 0.85rem;
  font-weight: 500;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

export const Title = styled.div`
  margin: 0;
  padding: 0.2rem 0rem 0rem 0rem;
  display: inline;
  color: black;
  font-size: 1rem;
  font-weight: 800;
`;
