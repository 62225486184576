import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cardId: "",
  title: "",
  labels: [],
  members: [],
  watchers: [],
  activities: undefined,
  checklists: [],
  owner: "",
  description: "",
  date: {
    startDate: null,
    dueDate: null,
    dueTime: null,
    completed: false,
  },
  attachments: [],
  cover: {
    color: null,
    isSizeOne: null,
  },
  colors: [
    { bg: "#61bd4f", hbg: "#519839" },
    { bg: "#f2d600", hbg: "#d9b51c" },
    { bg: "#ff9f1a", hbg: "#cd8313" },
    { bg: "#eb5a46", hbg: "#b04632" },
    { bg: "#c377e0", hbg: "#89609e" },
    { bg: "#0079bf", hbg: "#055a8c" },
    { bg: "#344563", hbg: "#172b4d" },
    { bg: "#ff78cb", hbg: "#c75bad" },
  ],
  listStandLogs: [],
  pending: false,
};

const cardsSlice = createSlice({
  name: "card",
  initialState,
  reducers: {
    reset: (state) => initialState,
    setPending: (state, action) => {
      state.pending = action.payload;
    },
    setCard: (state, action) => {
      state.cardId = action.payload._id;
      state.title = action.payload.title;
      state.labels = action.payload.labels;
      state.members = action.payload.members;
      state.watchers = action.payload.watchers;
      state.owner = action.payload.owner;
      state.listTitle = action.payload.listTitle;
      state.listId = action.payload.listId;
      state.boardId = action.payload.boardId;
      state.description = action.payload.description;
      state.checklists = action.payload.checklists;
      state.date = action.payload.date;
      state.attachments = action.payload.attachments;
      state.cover = action.payload.cover;
      state.timeTracking = action.payload.timeTracking;
      state.comments = action.payload.comments;
      state.listStandLogs = action.payload.listStandLogs;
      state.activities = undefined;
    },

    updateCardDetail: (state, action) => {
      if (state.cardId === action.payload._id) {
        Object.keys(action.payload).forEach((key) => {
          state[key] = action.payload[key];
        });
      }
    },

    updateListId: (state, action) => {
      state.listId = action.payload;
      state.owner = action.payload;
    },
    setCardActivities: (state, action) => {
      state.activities = action.payload;
    },
    updateTitle: (state, action) => {
      state.title = action.payload;
    },
    updateDescription: (state, action) => {
      state.description = action.payload;
    },
    addComment: (state, action) => {
      state.comments = action.payload;
    },
    updateComment: (state, action) => {
      const { commentId, text } = action.payload;
      state.comments = state.comments.map((comment) => {
        if (comment._id === commentId) {
          comment.text = text;
        }
        return comment;
      });
    },
    deleteComment: (state, action) => {
      state.comments = state.comments.filter(
        (act) => act._id !== action.payload
      );
    },
    addMember: (state, action) => {
      const { memberId, memberName, memberColor } = action.payload;
      state.members.unshift({
        user: memberId,
        name: memberName,
        color: memberColor,
      });
    },
    deleteMember: (state, action) => {
      const { memberId } = action.payload;
      state.members = state.members.filter(
        (member) => member.user !== memberId
      );
    },
    createLabel: (state, action) => {
      const { _id, text, color, backColor } = action.payload;
      state.labels.unshift({ _id, text, color, backColor, selected: true });
    },
    updateLabel: (state, action) => {
      const { labelId, text, color, backColor } = action.payload;
      state.labels = state.labels.map((label) => {
        if (label._id === labelId) {
          label.text = text;
          label.color = color;
          label.backColor = backColor;
        }
        return label;
      });
    },
    deleteLabel: (state, action) => {
      state.labels = state.labels.filter(
        (label) => label._id !== action.payload
      );
    },
    updateLabelSelection: (state, action) => {
      const { labelId, selected, label } = action.payload;
      if (selected) {
        state.labels.push(label);
      } else {
        state.labels = state.labels.filter((label) => label._id !== labelId);
      }
    },
    createChecklist: (state, action) => {
      const { _id, title } = action.payload;
      if (!state.checklists) state.checklists = [];

      state.checklists.push({ _id, title, items: [] });
    },
    deleteChecklist: (state, action) => {
      state.checklists = (state.checklists || []).filter(
        (list) => list._id !== action.payload
      );
    },
    addChecklistItem: (state, action) => {
      const { checklistId, _id, text } = action.payload;
      state.checklists = (state.checklists || []).map((list) => {
        if (list._id.toString() === checklistId.toString()) {
          list.items.push({ _id: _id, text: text, completed: false });
        }
        return list;
      });
    },
    setChecklistItemCompleted: (state, action) => {
      const { checklistId, checklistItemId, completed } = action.payload;
      state.checklists = (state.checklists || []).map((list) => {
        if (list._id.toString() === checklistId.toString()) {
          list.items = list.items.map((item) => {
            if (item._id === checklistItemId) {
              item.completed = completed;
            }
            return item;
          });
        }
        return list;
      });
    },
    setChecklistItemText: (state, action) => {
      const { checklistId, checklistItemId, text } = action.payload;
      state.checklists = (state.checklists || []).map((list) => {
        if (list._id.toString() === checklistId.toString()) {
          list.items = list.items.map((item) => {
            if (item._id === checklistItemId) {
              item.text = text;
            }
            return item;
          });
        }
        return list;
      });
    },
    deleteChecklistItem: (state, action) => {
      const { checklistId, checklistItemId } = action.payload;
      state.checklists = (state.checklists || []).map((list) => {
        if (list._id.toString() === checklistId.toString()) {
          list.items = list.items.filter(
            (item) => item._id !== checklistItemId
          );
        }
        return list;
      });
    },
    updateStartDueDates: (state, action) => {
      const { startDate, dueDate, dueTime } = action.payload;
      state.date.startDate = startDate;
      state.date.dueDate = dueDate;
      state.date.dueTime = dueTime;
      if (dueDate === null) state.date.completed = false;
    },
    updateDateCompleted: (state, action) => {
      state.date.completed = action.payload;
    },
    addAttachment: (state, action) => {
      const { link, name, _id, date, fileType, thumbnail } = action.payload;
      state.attachments.push({
        link: link,
        name: name,
        _id: _id,
        date: date,
        fileType,
        thumbnail,
      });
    },
    updateAddedAttachmentId: (state, action) => {
      state.attachments = state.attachments.map((attachment) => {
        if (attachment._id === "notUpdated") {
          attachment._id = action.payload;
        }
        return attachment;
      });
    },
    deleteAttachment: (state, action) => {
      const attachment = state.attachments.find(
        (attachment) => attachment._id === action.payload
      );
      state.attachments = state.attachments.filter(
        (attachment) => attachment._id !== action.payload
      );
      if (attachment.thumbnail === state.cover.thumbnail)
        state.cover = undefined;
    },
    updateAttachment: (state, action) => {
      const { attachmentId, link, name } = action.payload;
      state.attachments = state.attachments.map((attachment) => {
        if (attachment._id === attachmentId) {
          attachment.link = link;
          attachment.name = name;
        }
        return attachment;
      });
    },
    updateCover: (state, action) => {
      const { color, isSizeOne, thumbnail } = action.payload;
      state.cover.color = color;
      state.cover.isSizeOne = isSizeOne;
      state.cover.thumbnail = thumbnail;
    },
    updateEstimateTime: (state, action) => {
      state.timeTracking.estimateTime = action.payload;
    },
  },
});

export const {
  reset,
  setPending,
  setCard,
  updateCardDetail,
  updateTitle,
  updateDescription,
  addComment,
  updateComment,
  deleteComment,
  addMember,
  deleteMember,
  createLabel,
  updateLabel,
  deleteLabel,
  updateLabelSelection,
  createChecklist,
  deleteChecklist,
  addChecklistItem,
  setChecklistItemCompleted,
  setChecklistItemText,
  deleteChecklistItem,
  updateStartDueDates,
  updateDateCompleted,
  addAttachment,
  updateAddedAttachmentId,
  deleteAttachment,
  updateAttachment,
  updateCover,
  updateEstimateTime,
  setCardActivities,
  updateListId,
} = cardsSlice.actions;
export default cardsSlice.reducer;
