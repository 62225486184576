import axios from "axios";
import {
  registrationStart,
  registrationEnd,
  loginStart,
  loginFailure,
  loginSuccess,
  loadSuccess,
  loadFailure,
  loadStart,
  fetchingStart,
  fetchingFinish,
  logout,
} from "../Redux/Slices/userSlice";
import { openAlert } from "../Redux/Slices/alertSlice";
import setBearer from "../Utils/setBearer";
const baseUrl = process.env.REACT_APP_API_URL + "/user/";

export const register = async (
  { name, surename, email, password, repassword },
  dispatch
) => {
  dispatch(registrationStart());
  if (password !== repassword) {
    dispatch(
      openAlert({
        message: "Your passwords does not match!",
        severity: "error",
      })
    );
  } else {
    try {
      const res = await axios.post(`${baseUrl}register`, {
        name,
        surename,
        email,
        password,
      });
      dispatch(
        openAlert({
          message: res.data.message,
          severity: "success",
          nextRoute: "/login",
          duration: 1500,
        })
      );
    } catch (error) {
      dispatch(
        openAlert({
          message: error?.response?.data?.errMessage
            ? error.response.data.errMessage
            : error.message,
          severity: "error",
        })
      );
    }
  }
  dispatch(registrationEnd());
};

export const login = async ({ email, password }, dispatch, redirect) => {
  dispatch(loginStart());
  try {
    const res = await axios.post(baseUrl + "login", { email, password });
    const { user, message } = res.data;
    localStorage.setItem("token", user.token);
    setBearer(user.token);
    dispatch(loginSuccess({ user }));
    dispatch(
      openAlert({
        message,
        severity: "success",
        duration: 500,
        nextRoute: redirect,
      })
    );
  } catch (error) {
    dispatch(loginFailure());
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const loginWithGoogle = async (token, dispatch) => {
  dispatch(loginStart());
  try {
    const res = await axios.post(baseUrl + "auth/google", {
      token,
    });
    const { user, message } = res.data;
    localStorage.setItem("token", user.token);
    setBearer(user.token);
    dispatch(loginSuccess({ user }));
    dispatch(
      openAlert({
        message,
        severity: "success",
        duration: 500,
      })
    );
  } catch (error) {
    dispatch(loginFailure());
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
  }
};

export const loadUser = async (dispatch) => {
  dispatch(loadStart());
  if (!localStorage.token) return dispatch(loadFailure());
  setBearer(localStorage.token);
  try {
    await axios
      .get(baseUrl + "get-user")
      .then((res) => {
        dispatch(loadSuccess({ user: res.data }));
      })
      .catch((error) => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          dispatch(loadFailure());
        } else {
          dispatch(loadFailure());
        }
      });
  } catch (error) {
    dispatch(loadFailure());
  }
};

export const getUserFromEmail = async (email, dispatch) => {
  dispatch(fetchingStart());
  if (!email) {
    dispatch(
      openAlert({
        message: "Please write an email to invite",
        severity: "warning",
      })
    );
    dispatch(fetchingFinish());
    return null;
  }

  try {
    const res = await axios.post(baseUrl + "get-user-with-email", { email });
    dispatch(fetchingFinish());
    return res.data;
  } catch (error) {
    dispatch(
      openAlert({
        message: error?.response?.data?.errMessage
          ? error.response.data.errMessage
          : error.message,
        severity: "error",
      })
    );
    dispatch(fetchingFinish());
    return null;
  }
};

export const userLogout = (dispatch) => {
  axios.delete(baseUrl + "logout");
  dispatch(logout());
};

export const requestResetPassword = async (data) => {
  return axios.post(baseUrl + "request-reset-password", data);
};

export const resetPassword = async (data) => {
  return axios.post(baseUrl + "reset-password", data);
};

export const completeInvite = async (data) => {
  return axios.post(baseUrl + "complete-invite", data);
};

export const changePassword = async (data) => {
  return axios.post(baseUrl + "change-password", data);
};

export const updateProfile = async (data) => {
  return axios.post(baseUrl + "update-profile", data);
};

export const updateAvatar = async (data) => {
  return axios.post(baseUrl + "change-avatar", data);
};
