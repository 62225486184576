import axios from "axios";

const baseUrl = process.env.REACT_APP_API_URL + "/contact";

export const createContact = async (data) => {
  try {
    return axios.post(baseUrl, data);
  } catch (error) {
    throw error;
  }
};
