import React from "react";
import { useSelector } from "react-redux";
import { Container, Log, LogTitle, Title, TitleWrapper } from "./Styled";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import moment from "moment";
import { secondsToTimeString } from "../../../../Utils/estimateTimeHelper";

const ListStandLogs = () => {
  const card = useSelector((state) => state.card);

  if (!card) return null;
  if (!card.listStandLogs) return null;

  const logs = {};
  card.listStandLogs.forEach((log) => {
    if (!logs[log.id]) {
      logs[log.id] = {
        count: 1,
        title: log.title,
        standSeconds: moment(log.timeOut || new Date()).diff(
          moment(log.timeIn),
          "seconds"
        ),
      };
    } else {
      logs[log.id].count++;
      logs[log.id].standSeconds += moment(log.timeOut || new Date()).diff(
        moment(log.timeIn),
        "seconds"
      );
    }
  });
  return (
    <Container>
      <TitleWrapper>
        <SplitscreenIcon fontSize="small" />
        <Title>Columns Stand time</Title>
      </TitleWrapper>
      {Object.keys(logs).map((listId) => {
        return (
          <Log key={listId}>
            <LogTitle>
              {logs[listId].title} ({logs[listId].count})
            </LogTitle>
            <LogTitle>
              {secondsToTimeString(logs[listId].standSeconds)}
            </LogTitle>
          </Log>
        );
      })}
    </Container>
  );
};

export default ListStandLogs;
