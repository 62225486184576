import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Background from "../../Components/Background";
import { register } from "../../Services/userService";
import { useDispatch, useSelector } from "react-redux";
import {
  BgContainer,
  Container,
  TrelloIconContainer,
  FormSection,
  FormCard,
  Form,
  Title,
  Input,
  Button,
  Text,
  Icon,
  Hr,
  Link,
  PasswordInputGroup,
  PasswordInput,
  PasswordShowIcon,
} from "./Styled";
import { useEffect } from "react";
import logo from "../../Images/logo.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import GoogleLoginButton from "../LoginPage/GoogleLoginButton";
import { GoogleOAuthProvider } from "@react-oauth/google";
import BoardFooter from "../../Components/BoardFooter";
import IndexNav from "../../Components/IndexNav";

const Register = () => {
  let nvaigate = useNavigate();
  const dispatch = useDispatch();
  const { pending } = useSelector((state) => state.user);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [userInformations, setUserInformations] = useState({
    name: "",
    surename: "",
    email: "",
    password: "",
    repassword: "",
  });

  useEffect(() => {
    document.title =
      "Register | OlO Tasks: Free task management for anyone, anywhere and anytime";
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await register(userInformations, dispatch);
  };

  if (localStorage.getItem("token")) return <Navigate push to="/boards" />;

  return (
    <>
      <Container>
        <IndexNav isColor />
        <FormCard>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Title>Sign up for your account</Title>
            <Input
              type="text"
              placeholder="Enter name"
              required
              value={userInformations.name}
              onChange={(e) =>
                setUserInformations({
                  ...userInformations,
                  name: e.target.value,
                })
              }
            />
            <Input
              type="text"
              placeholder="Enter surename"
              required
              value={userInformations.surename}
              onChange={(e) =>
                setUserInformations({
                  ...userInformations,
                  surename: e.target.value,
                })
              }
            />
            <Input
              type="email"
              placeholder="Enter email"
              required
              value={userInformations.email}
              onChange={(e) =>
                setUserInformations({
                  ...userInformations,
                  email: e.target.value,
                })
              }
            />
            <PasswordInputGroup>
              <PasswordInput
                type={isShowPassword ? "text" : "password"}
                placeholder="Enter password"
                required
                value={userInformations.password}
                onChange={(e) =>
                  setUserInformations({
                    ...userInformations,
                    password: e.target.value,
                  })
                }
              />
              <PasswordShowIcon
                onClick={() => setIsShowPassword(!isShowPassword)}
              >
                {isShowPassword ? (
                  <VisibilityOffIcon fontSize="small" />
                ) : (
                  <VisibilityIcon fontSize="small" />
                )}
              </PasswordShowIcon>
            </PasswordInputGroup>

            <Input
              type="password"
              placeholder="Confirm password"
              required
              value={userInformations.repassword}
              onChange={(e) =>
                setUserInformations({
                  ...userInformations,
                  repassword: e.target.value,
                })
              }
            />
            <Text>
              By signing up, you confirm that you've read and accepted our{" "}
              <Link fontSize="0.75rem" to={"/terms-of-service"}>
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link fontSize="0.75rem" to={"/privacy-policy"}>
                Privacy Policy
              </Link>
              .
            </Text>
            <Button type="submit" disabled={pending}>
              Complete
            </Button>
            <Title>Or continue with:</Title>
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_google_client_id}
            >
              <GoogleLoginButton />
            </GoogleOAuthProvider>
            <Hr />
            <Link fontSize="0.85rem" to={"/login"}>
              Already have an account? Log In
            </Link>
          </Form>
        </FormCard>
        <BoardFooter isColor />
      </Container>
    </>
  );
};

export default Register;
