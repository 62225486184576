import React, { useEffect, useRef, useState } from "react";
import {
  Container,
  LeftContainer,
  RightContainer,
  Title,
  CommentWrapper,
  SaveButton,
  CommentArea,
  TitleWrapper,
} from "./styled";
import MessageIcon from "@mui/icons-material/MessageOutlined";
import Comment from "../Comment/Comment";
import ActivityLog from "../ActivityLog/ActivityLog";
import Button from "../../../ReUsableComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  comment,
  uploadSingleAttachment,
} from "../../../../Services/cardService";
import { Avatar } from "@mui/material";
import { CommentEditorContainer } from "../Comment/styled";
import QuillEditor from "../../../QuillEditor";
import AvatarIcon from "../../../AvatarIcon";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { orderBy } from "lodash-es";
import { userFullname } from "../../../../Utils/common";
import ListStandLogs from "../ListStandLogs";

const Activity = () => {
  const dispatch = useDispatch();
  const ref = useRef();
  const card = useSelector((state) => state.card);
  const user = useSelector((state) => state.user);
  const board = useSelector((state) => state.board);
  const [focusComment, setFocusComment] = useState(false);
  const [newComment, setNewComment] = useState("");
  const [details, setDetails] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [activitySort, setActivitySort] = useState(-1);
  const [readyToSave, setReadyToSave] = useState(true);

  const handleSaveClick = async () => {
    await comment(
      card.cardId,
      card.boardId,
      {
        content: newComment,
        mentions,
      },
      dispatch
    );
    setNewComment("");
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setFocusComment(false);
    } else {
      setFocusComment(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const uploadImageHandler = async (file) => {
    const res = await uploadSingleAttachment(
      card.cardId,
      board.shortId,
      file,
      dispatch
    );

    return res.data.attachments[res.data.attachments.length - 1].link;
  };

  const activitySortHandler = () => {
    setActivitySort((prev) => (prev === -1 ? 1 : -1));
  };

  return (
    <>
      <Container>
        <RightContainer>
          <TitleWrapper>
            <div
              style={{ gap: "0.5em", display: "flex", flexDirection: "row" }}
            >
              <Title>Activity</Title>
              <Button onClick={activitySortHandler}>
                {activitySort === -1 ? (
                  <ArrowUpward fontSize="inherit" />
                ) : (
                  <ArrowDownward fontSize="inherit" />
                )}
                {activitySort === -1 ? "Newest" : "Oldest"}
              </Button>
            </div>

            <Button
              onClick={() => setDetails((prev) => !prev)}
              title={details ? "Hide details" : "Show details"}
            />
          </TitleWrapper>
        </RightContainer>
      </Container>
      {orderBy(
        card.comments || [],
        ["createdAt"],
        activitySort == 1 ? "asc" : "desc"
      )?.map((comment) => {
        return <Comment key={comment._id} {...comment} />;
      })}
      <Container style={{ marginTop: "1.5em" }}>
        <LeftContainer>
          <MessageIcon fontSize="small" />
          <AvatarIcon {...user.userInfo} />
        </LeftContainer>
        <RightContainer>
          <CommentWrapper ref={ref}>
            <SaveButton
              disabled={!newComment || !readyToSave}
              onClick={handleSaveClick}
              show={focusComment}
            >
              Save
            </SaveButton>
            <CommentEditorContainer>
              <QuillEditor
                onChanged={(e) => setNewComment(e)}
                value={newComment}
                onFocus={focusComment}
                placeholder="Mention with @, Write a comment..."
                onMention={(e) => setMentions(e)}
                users={board.members
                  .filter((u) => u.user != user._id)
                  .map((member) => ({
                    id: member.user,
                    value: userFullname(member),
                  }))}
                uploadImageHandler={uploadImageHandler}
                isReady={setReadyToSave}
              />
            </CommentEditorContainer>
          </CommentWrapper>
        </RightContainer>
      </Container>
      {details && (
        <>
          <ActivityLog sort={activitySort == 1 ? "asc" : "desc"} />
        </>
      )}
    </>
  );
};

export default Activity;
