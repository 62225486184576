import React, { useState } from "react";
import styled from "styled-components";
import DropdownMenu from "./DropdownMenu";
import SearchBar from "./SearchBar";
import { xs } from "../BreakPoints";
import ProfileBox from "./ProfileBox";
import { useNavigate } from "react-router-dom";
import logo_white from "../Images/olotasks_white.svg";
import UserNotifications from "./UserNotifications";
import { useSelector } from "react-redux";

const Container = styled.div`
  height: 3rem;
  width: 100%;
  background-color: ${(props) =>
    props.color ? "#4a708a" : "rgba(0, 0, 0, 0.3)"};
  backdrop-filter: blur(24px);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  ${xs({
    padding: "0.5rem, 0rem",
  })}
`;

const LeftSide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${xs({
    gap: "0.1rem",
    width: "fit-content",
  })}
`;

const RightSide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TrelloLogo = styled.img`
  width: 75px;
  height: 15px;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${xs({
    display: "none",
  })}
`;

const Navbar = ({ allowSearch, isColor }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const board = useSelector((state) => state.board);

  const isAuth = user.isAuthenticated && !user.pending;
  return (
    <Container color={isColor}>
      <LeftSide>
        <LogoContainer>
          <TrelloLogo
            onClick={() => {
              navigate("/boards");
            }}
            src={logo_white}
          />
        </LogoContainer>
        {isAuth && (
          <DropdownContainer>
            <DropdownMenu
              title={board ? board.title || "Your Boards" : "Your Boards"}
            />
          </DropdownContainer>
        )}
      </LeftSide>
      <RightSide>
        {isAuth && (
          <>
            {allowSearch && <SearchBar />}
            <UserNotifications content={4} />
            <ProfileBox />
          </>
        )}
      </RightSide>
    </Container>
  );
};

export default Navbar;
