import React, { useState } from "react";
import { Container, Title } from "./styled";
import Button from "../../../ReUsableComponents/IconButton";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ArchiveIcon from "@mui/icons-material/Archive";
import { useDispatch, useSelector } from "react-redux";
import ConfirmModal from "../../../ConfirmModal";
import { cardDelete } from "../../../../Services/boardService";
import { archiveCard } from "../../../../Services/cardService";

const Actions = (props) => {
  const card = useSelector((state) => state.card);
  const [deletePopover, setDeletePopover] = useState(false);
  const dispatch = useDispatch();
  const deleteCard = () => {
    cardDelete(card.listId, card.boardId, card.cardId, dispatch).then(() => {
      props.closeModal();
    });
  };

  const archiveCardClick = () => {
    archiveCard(card.cardId, card.boardId, dispatch).then(() => {
      props.closeModal();
    });
  };

  return (
    <Container>
      <Title>Actions</Title>
      <Button
        onClick={archiveCardClick}
        title="Archive"
        icon={<ArchiveIcon fontSize="small" />}
      ></Button>
      <Button
        onClick={() => {
          setDeletePopover(true);
        }}
        color={"danger"}
        title="Delete"
        icon={<DeleteIcon fontSize="small" />}
      ></Button>
      {deletePopover && (
        <ConfirmModal
          open={true}
          closeHandle={() => setDeletePopover(false)}
          confirmHandle={deleteCard}
          title="Are you sure to delete card?"
        />
      )}
    </Container>
  );
};

export default Actions;
