import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { MoreHoriz, ArrowBack } from "@mui/icons-material";
import { ClickableIcon } from "../../Pages/BoardPage/CommonStyled";
import { Divider } from "@mui/material";
import Button from "../ReUsableComponents/Button";
import IconButton from "../ReUsableComponents/IconButton";
import styled from "styled-components";

const Title = styled.span`
  color: #5e6c84;
  font-weight: 600;
`;

const MenuContext = ({ menus, onItemClick, children }) => {
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const menuClick = (e) => {
    if (e.subs) {
      setSelectedMenu(e);
    } else {
      if (e.onClick) {
        e.onClick(e);
      } else {
        onItemClick && onItemClick(e);
      }
      handleClose();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSelectedMenu(null);
    setAnchorEl(null);
  };
  return (
    <>
      <ClickableIcon
        color="#656565"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {children || <MoreHoriz fontSize="0.1rem" />}
      </ClickableIcon>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paperProps: {
            style: {
              minWidth: "200px",
            },
          },
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* add back button to return menu */}
        {selectedMenu && (
          <>
            <div
              style={{
                padding: "0.5rem",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <IconButton
                title={"Back"}
                icon={<ArrowBack fontSize="small" />}
                onClick={() => setSelectedMenu(null)}
              />
              <Title>{selectedMenu.title}</Title>
            </div>
            <Divider />
          </>
        )}
        {selectedMenu
          ? selectedMenu.subs.map((item) => (
              <MenuItem sx={{ minWidth: 200 }} onClick={() => menuClick(item)}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText>{item.title}</ListItemText>
              </MenuItem>
            ))
          : menus.map((item) => (
              <MenuItem sx={{ minWidth: 200 }} onClick={() => menuClick(item)}>
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText>{item.title}</ListItemText>
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default MenuContext;
